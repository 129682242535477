.header {
  width: 100%;
  padding: 13px 0 14px;
  background-color: #ffffff;
  @include media("<=810px") {
    padding: 22px 0;
  }
  @include media("<=430px") {
    padding: 18px 0;
  }
  &.page {
    position: relative;
  }
}

.header__inner {
  max-width: 1110px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 20;
}

.header__nav {
  flex: 0 1 100%;
  width: 100%;
  max-width: 634px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media("<=810px") {
    display: none;
    max-width: 100%;
    &.active {
      display: flex;
      position: fixed;
      height: 45%;
      width: 100%;
      max-width: none;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: #FF3509;
      z-index: 20;
      margin-right: 0;
      margin-left: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-content: space-between;
      align-items: center;
      opacity: 0.97;
      @include media("<=430px") {
        padding-bottom: 0;
        justify-content: center;
      }
      .header__link {
        padding: 20px;
        @include media("<=430px") {
          padding: 15px;
        }
        @include media("<=350px") {
          padding: 10px;
        }
      }
    }
  }
}

.header__link {
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #000000;
  transition: all 0.3s ease;
  position: relative;
  @include media("<=810px") {
  }

  &:hover {
    color: #FF3509;
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  background: #000000;
  bottom: -6px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.logo {
  display: block;
  max-width: 150px;
  width: 100%;
  flex: 0 1 100%;
  @include media("<=810px") {
    position: relative;
    z-index: 21;
  }
    @include media("<=430px") {
      max-width: 130px;
    }
  img {
    width: 100%;
    display: block;
  }
}

