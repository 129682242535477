.skills {
    padding-bottom: 77px;
    .section__title {
        text-align: right;
    }
}

.skills__inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    column-gap: 44px;
    @include media("<=810px") {
        column-gap: 15px;
        align-items: center;
    }
}

.skills__image {
    flex: 0 1 100%;
    max-width: 301px;
    padding-top: 20px;
    @include media("<=810px") {
        max-width: 130px;
    }
        @include media("<=430px") {
            display: none;
        }
    img {
        width: 100%;
        display: block;
    }
  &._active {
      animation: jump 0.8s ease-in-out alternate;
      animation-delay: 0.2s;
  }
}

.skills__content {
    flex: 0 1 100%;
    max-width: 756px;
}

.skills__list {
    padding-top: 42px;
    display: flex;
    flex-direction: column;
    row-gap: 49px;
    @include media("<=810px") {
        row-gap: 30px;
    }
}

.skills__item {
    display: flex;
    column-gap: 45px;
    @include media("<=810px") {
        column-gap: 30px;
    }
        @include media("<=430px") {
            column-gap: 20px;
        }
}

.skills__icon {
    max-width: 40px;
    flex: 0 1 100%;
    @include media("<=430px") {
        max-width: 28px;
    }
    img {
        width: 100%;
    }
}

.skills__item-content {
    flex: 0 1 100%;
    font-size: 20px;
    line-height: 1.2;
    @include media("<=430px") {
        font-size: 18px;
    }
}

.skills__title {
    font-weight: 900;
    margin-bottom: 12px;
}