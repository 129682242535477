@font-face {
    font-family: "MuseoSansCyrl";
    src: url("../fonts/MuseoSansCyrl-300.eot");
    src: local("☺"), url("../fonts/MuseoSansCyrl-300.woff") format("woff"),
        url("../fonts/MuseoSansCyrl-300.ttf") format("truetype"),
        url("../fonts/MuseoSansCyrl-300.svg") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "MuseoSansCyrl";
    src: url("../fonts/MuseoSansCyrl-500.eot");
    src: local("☺"), url("../fonts/MuseoSansCyrl-500.woff") format("woff"),
        url("../fonts/MuseoSansCyrl-500.ttf") format("truetype"),
        url("../fonts/MuseoSansCyrl-500.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "MuseoSansCyrl";
    src: url("../fonts/MuseoSansCyrl-700.eot");
    src: local("☺"), url("../fonts/MuseoSansCyrl-700.woff") format("woff"),
        url("../fonts/MuseoSansCyrl-700.ttf") format("truetype"),
        url("../fonts/MuseoSansCyrl-700.svg") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "MuseoSansCyrl";
    src: url("../fonts/MuseoSansCyrl-900.eot");
    src: local("☺"), url("../fonts/MuseoSansCyrl-900.woff") format("woff"),
        url("../fonts/MuseoSansCyrl-900.ttf") format("truetype"),
        url("../fonts/MuseoSansCyrl-900.svg") format("svg");
    font-weight: 900;
    font-style: normal;
}