.about {
  position: relative;
  padding: 64px 0 109px;
  overflow-x: clip;
  background-image: url(/images/about-bcg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @include media("<=810px") {
    
  }
  @include media("<=420px") {
    
  }
    .button {
      display: block;
      padding: 26px;
      max-width: 466px;
      margin: 0 auto;
    }
    .section__title {
      margin-left: 0;
    }
}

.about__video {
  width: 100%;
  max-height: 826px;
  aspect-ratio: 16 / 9;
  background-color: #000000;
  object-fit: cover;
}

.about__inner {
  padding-top: 37px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 2;
  @include media("<=430px") {
    
  }
}

.about__text {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  padding-bottom: 64px;
  opacity: 0;
    transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=1080px") {
    font-size: 22px;
  }
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    padding-bottom: 50px;
    font-size: 18px;
    
  }
}

.about__content {
  width: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding-bottom: 127px;
  @include media("<=810px") {
    flex-wrap: wrap;
    padding-bottom: 40px;
  }
    @include media("<=430px") {
      flex-direction: column;
      align-items: center;
    }
}

.about__item {
  flex: 0 1 33%;
  max-width: 226px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  &:nth-of-type(2) {
    transition: all 0.8s ease 0.4s;
  }
    &:nth-of-type(3) {
      transition: all 0.8s ease 0.6s;
    }
    &._active {
      opacity: 1;
    }
  @include media("<=810px") {
    flex: 0 1 50%;
    max-width: none;
    margin-bottom: 20px;
  }
    @include media("<=430px") {
      flex: 0 1 100%;
      max-width: 200px;
      width: 100%;
      margin-bottom: 30px;
    }
  &-text {
    padding-top: 69px;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    max-width: 133px;
    @include media("<=810px") {
      padding-top: 40px;
    }
    @include media("<=430px") {
      padding-top: 20px;
    }
  }
}

.about__number {
  max-width: 226px;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: 15px #FF3509 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 900;
  @include media("<=430px") {
    font-size: 36px;
  }
}

