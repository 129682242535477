.reviews {
  padding: 42px 0 114px;
  background-image: url(../images/about-bcg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @include media("<=430px") {
    padding-bottom: 60px;
  }
}

.reviews__inner {
  padding-top: 22px;
}

.slider {
  width: 74%;
  position: relative;
  flex: 0 1 100%;
  z-index: 3;
  max-width: 1364px;
  margin-left: auto;
  margin-right: 0;
  
  @include media("<=1280px") {
    width: 82%;
  }

  @include media("<=1080px") {
    width: 92%;
  }

  @include media("<=810px") { 
    margin: 0 auto;
    padding: 0 10px;
    padding-bottom: 120px;
  }

  @include media("<=430px") {
    width: 100%;
    padding: 0 0 100px;
  }

  @include media("<=320px") {}
  
}

.slick-list {
  padding: 0 !important;
  overflow: hidden;
  @include media("<=430px") {
    
  }
}

.slick-track {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include media("<=430px") {
    justify-content: space-around;
  }
}

.slider__item {
  max-width: 672px;
  width: 100%;
  margin: 0 10px;

  @include media("<=430px") {
    
  }
}

.slide__text {
  padding: 0 4vw;
  height: 276px;
  width: 100%;
  background-color: rgba(241, 252, 248, 0.5);
  border-radius: 50px 50px 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 19px;
  line-height: 1.2;
  margin-bottom: 34px;
  @include media("<=1440px") {
    font-size: 18px;
  }
    @include media("<=1280px") {
      font-size: 17px;
    }
    @include media("<=1080px") {
      padding: 0 2vw;
    }
    @include media("<=810px") {
      padding: 0 4vw;
      font-size: 18px;
    }
    @include media("<=430px") {
      font-size: 16px;
      height: 340px;
    }
}  

.slide__member {
  display: flex;
  align-items: center;
}

.slide__image {
  max-width: 80px;
  margin-right: 50px;
  img {
    width: 100%;
    display: block;
  }
}

.slide__info {
  font-size: 18px;
  line-height: 1.2;
}

.slide__title {
  font-weight: 900;
}

.slick-slide {
  position: relative;
  cursor: pointer;
  transition: all 0.3s linear;
  

  @include media("<=430px") {
    
  }
}

.slick-arrow {
  position: absolute;
  z-index: 10;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 100%;
  max-width: 80px;
  max-height: 22px;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;

  transition: all 0.3s linear;

  &:hover {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  }

  @include media("<=810px") {
    
    
  }

  @include media("<=430px") {
    max-width: 55px;
      max-height: 15px;
  }

  @include media("<=375px") {}

  @include media("<=320px") {}

  &.slick-prev {
    left: -8vw;
    bottom: 36px;
    background-image: url(../images/prev.png);
   
    @include media("<=810px") {
      left: 30%;
      bottom: 0;
    }

    @include media("<=430px") {
      
    }
  }

  &.slick-next {
    
    display: none;

    @include media("<=810px") {
      background-image: url(../images/next.png);
      display: block;
      right: 30%;
      bottom: 0;
    }

    @include media("<=430px") {
      
    }
  }
}