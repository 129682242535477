.info {
  margin-top: -123px;
  margin-bottom: 71px;
  @include media("<=810px") {
    margin-top: 0;
  }
  .section__title {
    @include media("<=810px") {
      text-align: center;
    }
  }
}

.info__inner {
  padding-top: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @include media("<=810px") {
    flex-direction: column;
  }
    @include media("<=430px") {
      padding-top: 40px;
    }
}

.info__item {
  flex: 0 1 49%;
  max-width: 556px;
  background-color: rgba(225, 243, 244, 0.5);
  border-radius: 50px;
  height: 182px;
  display: flex;
  align-items: center;
  padding-left: 56px;
  padding-right: 46px;
  margin-bottom: 40px;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  &:nth-of-type(2) {
    transition: all 0.8s ease 0.4s
  }
    &:nth-of-type(3) {
      transition: all 0.8s ease 0.6s
    }
        &:nth-of-type(4) {
          transition: all 0.8s ease 0.8s
        }
    &._active {
      opacity: 1;
    }
  @include media("<=810px") {
    aspect-ratio: 16 / 6;
    flex: 0 1 100%;
    width: 100%;
  }
  @include media("<=430px") {
    padding: 0 20px;
    border-radius: 30px;
    margin-bottom: 30px;
  }
  &-number {
    color: #FF3509;
    font-weight: 900;
    font-size: 64px;
    @include media("<=430px") {
      font-size: 40px;
    }
  }
  &-text {
    margin-left: 20px;
    font-size: 20px;
    line-height: 1.2;
    @include media("<=430px") {
      font-size: 18px;
      margin-left: 15px;
    }
    @include media("<=350px") {
      font-size: 16px;
    }
  }
}
