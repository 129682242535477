.jobs__header {
  padding: 58px 0 102px;
  background-image: url(../images/jobs-bcg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @include media("<=430px") {
    overflow: hidden;
    padding-bottom: 80px;
  }
  &-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include media("<=810px") {
      flex-direction: column;
      align-items: center;
    }
  }
    .section__title {
    //  margin-left: 0;
    }
}

.jobs__content {
  padding-top: 24px;
  flex: 0 1 100%;
  max-width: 634px;
  @include media("<=810px") {
    max-width: 460px;
    margin-bottom: 30px;
  }
}

.jobs__list {
  padding-top: 24px;
  opacity: 0;
    transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
  &-title {
    font-weight: 900;
    font-size: 20px;
  }
  &-item {
    font-size: 20px;
    line-height: 1.2;
    @include media("<=430px") {
      font-size: 16px;
    }
  }
}

.jobs__image {
  margin-top: -30px;
  flex: 0 1 100%;
  max-width: 540px;
  position: relative;
  opacity: 0;
    transition: all 0.8s ease 0.4s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=810px") {
    margin-top: 0;
  }
    @include media("<=430px") {
      width: 110%;
    }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    @include media("<=810px") {
      position: relative;
      top: auto;
      left: auto;
    }
  }
}

.jobs {
  background-image: url(../images/about-bcg.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  padding: 40px 0 90px;
  @include media("<=810px") {
  }
}

.jobs__inner {
  width: 100%;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 4;
}

.tabs {
  position: relative;
  width: 100%;
  @include media("<=810px") {}
}

.jobs__item {
  width: 100%;
  overflow-x: clip;
  padding-top: 16px;
  margin-bottom: 50px;
  position: relative;
 
  @include media("<=430px") {
    margin-bottom: 30px;
  }
}

.jobs__text {
  width: 92%;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 300;
  text-transform: none;
  max-height: 0;
  padding-left: 140px;
  transition: all 0.35s linear;
  text-transform: uppercase;
  color: #000000;
  position: relative;
  top: -40px;
  z-index: -1;
  opacity: 0;
  display: flex;
  flex-direction: column;
  @include media("<=810px") {}

  @include media("<=430px") {
    font-size: 14px;
    padding-left: 10px;
    width: 98%;
  }
}

.jobs__link {
  margin-bottom: 13px;
  background: rgba(217, 217, 217, 0.5);
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 20px;
  font-weight: 900;
  padding: 17px 14px;
  position: relative;
  @include media("<=430px") {
    padding: 7px 4px;
    font-size: 16px;
  }
  &::after {
      content: "";
      position: absolute;
      top: 30%;
      right: 40px;
      width: 20px;
      height: 20px;
      border-bottom: 4px solid #FF3509;
      border-left: 4px solid #FF3509;
      border-radius: 3px;
      text-align: center;
      transform: rotate(-135deg);
      transition: all 0.35s ease-in-out;
  
      @include media("<=430px") {
        width: 12px;
        height: 12px;
        right: 15px;
      }
    }
}

.label {
  width: 100%;
  position: relative;
  background-color: #000000;
  color: #fff;
  padding: 22px 0 22px 154px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 900;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
  @include media("<=430px") {
    font-size: 18px;
    flex-direction: column;
    align-items: center;
    padding: 12px 0 12px 90px;
    text-align: center;
  }
  &::after {
    content: "";
    position: absolute;
    top: 30%;
    right: 44px;
    width: 20px;
    height: 20px;
    border-bottom: 4px solid #fff;
    border-left: 4px solid #fff;
    border-radius: 3px;
    text-align: center;
    transform: rotate(-45deg);
    transition: all 0.35s ease-in-out;

    @include media("<=430px") {
      position: relative;
      top: auto;
      right: auto;
    }
  }

  
}

.label__image {
  background-color: #FF3509;
    max-width: 116px;
    width: 100%;
    border-radius: 50px;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    @include media("<=430px") {
      max-width: 80px;
      border-radius: 30px;
    }
    img {
      width: 65%;
      max-width: 72px;
      display: block;
    }
}

input:checked {
  +.label {
    &::after {
      transform: rotate(135deg);
    }
  }

  ~.jobs__text {
    max-height: 100vh;
    padding-top: 17px;
    top: auto;
    opacity: 1;
    z-index: 3;
  }
}

input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

