.footer {
  background-color: #000000;
  @include media("<=430px") {
    
  }
  .section__title {
    color: #ffffff;
  }
}

.footer__inner {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  text-align: center;
  @include media("<=1080px") {
  }
  @include media("<=810px") {
    flex-wrap: wrap;
  }
  @include media("<=430px") {
    
  }
}

.footer__content {
  flex: 0 1 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 29px;
  @include media("<=810px") {
    max-width: none;
  }
  @include media("<=430px") {
    padding-bottom: 15px;
  }
}

.contacts {
  padding-top: 4px;
}

.contacts__item {
  padding-top: 19px;
  @include media("<=430px") {
    padding-top: 12px;
  }
}

.contacts__link {
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  font-weight: 900;
  font-size: 24px;
  text-align: left;
  @include media("<=430px") {
    font-size: 20px;
  }
  img {
    max-width: 40px;
    width: 100%;
    margin-right: 23px;
    @include media("<=430px") {
      max-width: 30px;
      margin-right: 15px;
    }
  }
  &:hover {
    text-decoration: underline;
  }
}

.copyright {
  width: 100%;
  flex: 0 1 100%;
  font-size: 14px;
  color: #ffffff;
  padding-bottom: 29px;
  @include media("<=810px") {
    flex: 0 1 55%;
    text-align: left;
  }
  @include media("<=430px") {
    flex: 0 1 60%;
    padding-bottom: 15px;
  }
}

.footer__image {
  position: relative;
  flex: 0 1 100%;
  max-width: 330px;
  width: 100%;
  transition: all 1s ease 0.2s;
  
    
  @include media("<=810px") {
    flex: 0 1 45%;
    height: 350px;
  }
  @include media("<=430px") {
    flex: 0 1 40%;
    height: auto;
    aspect-ratio: 1 / 1.5;
  }

  img {
    position: absolute;
    bottom: 0;
    right: 0;
    &._active {
        animation: jump 0.8s ease-in-out alternate;
        animation-delay: 0.2s;
      }
    @include media("<=430px") {
      width: 50vw;
    }
  }
  &-about {
    img {
      @include media("<=430px") {
          width: 65vw;
          right: -15px;
        }
    }
  }
  &-jobs {
    bottom: 48px;
    img {
      @include media("<=430px") {
          width: 68vw;
        }
    }
  }
  &-reviews {
    bottom: 38px;
    img {
        @include media("<=430px") {
          width: 50vw;
        }
      }
  }
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0);
  }

  40% {
    transform: translate3d(0, 0, 0) scale3d(1.2, 0.8, 1);
  }

  60% {
    transform: translate3d(0, 0, 0) scale3d(0.9, 1.2, 1);
  }
    100% {
      transform: translate3d(0, 0, 0);
    }
}