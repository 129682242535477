.hero {
  background-image: url(../images/hero-bcg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
  overflow-x: clip;
  padding: 35px 0 320px;
  @include media("<=810px") {
    padding: 50px 0 100px;
    background-position-x: 37%;
    background-size: 365%;
  }
  @include media("<=430px") {
    padding-bottom: 120px;
      background-position-x: 35%;
      background-size: 565%;
  }
  .button {
    @include media("<=810px") {
      position: absolute;
      bottom: 0;
    }
  }
}

.hero__inner {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 90px;
  }
  @include media("<=430px") {
    
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
}

.hero__content {
  flex: 0 1 100%;
  max-width: 565px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 86px;
  @include media("<=1080px") {
    max-width: 552px;
    margin-right: 40px;
  }
  @include media("<=810px") {
    align-items: center;
    margin-right: 0;
    max-width: 480px;
  }
    @include media("<=430px") {
      margin-bottom: 5px;
    }
}

.hero__title {
  text-indent: -9999px;
  font-size: 0px;
  margin-bottom: 22px;
  color: #000000;
  max-width: 530px;
  width: 100%;
  margin-bottom: 8px;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=1280px") {
    
  }

  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    text-align: center;
    margin-bottom: 20px;
    
  }
  @include media("<=430px") {
    margin-bottom: 30px;
  }

  @include media("<=320px") {
  }
  img {
    width: 100%;
    display: block;
  }
}

.hero__subtitle {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 17px;
  line-height: 1.2;
  color: #000000;
  opacity: 0;
    transition: all 0.8s ease 0.4s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=1280px") {
    font-size: 34px;
  }
  @include media("<=1080px") {
    font-size: 32px;
  }
  @include media("<=810px") {
    font-size: 28px;
    margin-bottom: 20px;
  }
  @include media("<=430px") {
    font-size: 24px;
    margin-bottom: 12px;
  }
    @include media("<=350px") {
      font-size: 20px;
    }

}

.hero__text {
  color: #FFFFFF;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 22px;
  opacity: 0;
  transition: all 0.8s ease 0.6s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=810px") {
    margin-bottom: 0;
  }
  @include media("<=430px") {
    font-size: 16px;
  }
    @include media("<=350px") {
      font-size: 14px;
    }
}

.button {
  color: #FFFFFF;
  background-color: #000000;
  font-size: 20px;
  font-weight: 900;
  text-decoration: none;
  text-align: center;
  padding: 23px;
  max-width: 272px;
  width: 100%;
  border-radius: 50px;
  transition: all ease-in 0.3s;
  &:hover {
    box-shadow: 0 0 1px 2px #000000;
   // transform: scale(1.1);
  }
    @include media("<=430px") {
      padding: 20px;
      max-width: 220px;
      font-size: 18px;
    }
}

.hero__image {
  flex: 0 1 100%;
  max-width: 443px;
  position: relative;
&._active {
    animation: jump 0.8s ease-in-out alternate;
    animation-delay: 0.2s;
  }
  @include media("<=1080px") {
    max-width: 400px;
  }
  @include media("<=810px") {
    margin-top: -20px;
  }
  @include media("<=430px") {
    margin-top: 0;
    max-width: 240px;
  }
    @include media("<=350px") {
      max-width: 200px;
    }
  img {
    width: 100%;
    display: block;
  }
}




