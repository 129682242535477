.features {
  padding-bottom: 109px;
  .button {
    display: block;
    padding: 26px;
    max-width: 466px;
    margin: 0 auto;
  }
    .section__title {
      @include media("<=810px") {
        text-align: center;
      }
    }
}

.features__inner {
  padding-top: 49px;
  padding-bottom: 77px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include media("<=810px") {
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 60px;
  }
  @include media("<=430px") {
    
  }
}

.features__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 181px;
  flex: 0 1 16%;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  
    &:nth-of-type(2) {
      transition: all 0.8s ease 0.4s
    }
  
    &:nth-of-type(3) {
      transition: all 0.8s ease 0.6s
    }
  
    &:nth-of-type(4) {
      transition: all 0.8s ease 0.8s
    }
 &:nth-of-type(5) {
   transition: all 0.8s ease 1s
 }

 &:nth-of-type(6) {
   transition: all 0.8s ease 1.2s
 }
    &._active {
      opacity: 1;
    }
  @include media("<=810px") {
    max-width: 185px;
    flex: 0 1 33%;
    margin-bottom: 30px;
  }
  @include media("<=430px") {
    width: 100%;
    flex: 0 1 42%;
  }
  &-image {
    background-color: #FF3509;
    max-width: 181px;
    width: 100%;
    border-radius: 50px;
    aspect-ratio: 1 / 1;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media("<=810px") {
      margin-bottom: 20px;
    }
    @include media("<=430px") {
      border-radius: 30px;
    }
    img {
      width: 65%;
      max-width: 113px;
      display: block;
    }
  }
  &-text {
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.2;
    @include media("<=1080px") {
      font-size: 18px;
    }
    @include media("<=430px") {
      font-size: 16px;
    }
  }
}